import axios from 'axios'

let requestList: string[] = []

let cancelToken = axios.CancelToken

export const Instance = axios.create(
    {
        timeout: 8000,
        baseURL: "http://fkapi.rebo-group.com"
        // baseURL: "http://127.0.0.1"
    }
)

Instance.interceptors.request.use(
    config => {
        config.cancelToken = new cancelToken((cancel) => {
            let requestFlag = JSON.stringify(config.url) + JSON.stringify(config.data) + "&" + config.method
            if (requestList.includes(requestFlag)) {
                cancel()
            } else {
                requestList.push(requestFlag)
            }
        })
        return config
    }
)

Instance.interceptors.response.use(
    success => {
        return success
    },
    error => {
        return error
    }
)