import { Instance } from '../utils/request'

export const getAllVistor = () => {
    return Instance({
        url: "/v2/listPerson",
        method: "get"
    })
}
export const userLogic = (param: any, query: string) => {
    return Instance({
        url: "/v2/logic" + query,
        method: "POST",
        data: param
    })
}

export const SubVisForm = (param: any) => {
    return Instance({
        url: "/v2/saveOrder",
        method: "POST",
        data: param
    })
}

export const DealOrder = (param: any) => {
    return Instance({
        url: "/v2/dealOrder",
        method: "POST",
        data: param
    })
}

export const FindOrder = (param: string) => {
    return Instance({
        url: "/v2/findOrder" + param,
        method: "GET"
    })
}
export const ListOrg = () => {
    return Instance({
        url: "/v2/listOrg",
        method: "GET"
    })
}
export const ValidateOrder = (search: string) => {
    return Instance({
        url: "/v2/validateOrder" + search,
        method: "GET"
    })
}