import { Result } from 'antd-mobile'
import React from 'react'

const QrCodeFaildOutTime = () => {
    return (
        <div>
            <Result
                status='error'
                title='您的二维码已经失效'
                description='请返回公众号重新申请'
            />
        </div>
    )
}

export default QrCodeFaildOutTime
