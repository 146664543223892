import { Routes, Route } from "react-router-dom";
import "./App.css";
import ExaView from "./pages/ExaView";
import RigView from "./pages/RigView";
import VisView from "./pages/VisView";
import Success from "./pages/Success";
import Faild from "./pages/Faild";
import ValView from "./pages/ValView";
import QrCodeFaildOutTime from "./pages/QrCodeOutTime";
import QrCodeUnTime from "./pages/QrCodeUnTime";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/exa" element={<ExaView />} />
        <Route path="/rig" element={<RigView />} />
        <Route path="/vis" element={<VisView />} />

        <Route path="/ok" element={<Success />} />
        <Route path="/bad" element={<Faild />} />
        <Route path="/val" element={<ValView />} />
        <Route path="/qrOutime" element={<QrCodeFaildOutTime />} />
        <Route path="/qrUntime" element={<QrCodeUnTime />} />
      </Routes>
    </div>
  );
}

export default App;
