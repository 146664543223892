import { Card } from 'antd-mobile';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { ValidateOrder } from '../api/v1';


const ExaView = () => {

    const [state, setState] = useState({
        qrCode: "",
        name: "",
        endOn: "",
        relateVis: "",
        startOn: ""
    })

    const getVisitorState = async () => {
        let { search } = window.location
        console.log(search)
        const { data } = await ValidateOrder(search)
        return data
    }


    useEffect(() => {
        getVisitorState().then(res => {
            console.log(res.result)
            setState(res.result)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    // qrcode = qrcode.toUpperCase()

    return (
        <div>
            <Card title='您的访客码' >
                <QRCode
                    style={{ width: 300, height: 300, margin: 'auto', textAlign: 'center' }}
                    value={state.qrCode}  // value参数为生成二维码的链接
                    size={200} // 二维码的宽高尺寸
                    fgColor="#000000"  // 二维码的颜色
                />
            </Card>
            <Card title='姓名' >
                {state.name}
            </Card>
            <Card title='开始时间' >
                {state.startOn}
            </Card>
            <Card title='结束时间' >
                {state.endOn}
            </Card>
            <Card title='14天行程' >
                {state.relateVis}
            </Card>
        </div>
    )
}

export default ExaView
