import { Result } from 'antd-mobile'

const Success = () => {
    return (
        <div>
            <Result
                status='success'
                title='操作成功'
                description='请点击屏幕左上角关闭页面'
            />
        </div>
    )
}

export default Success
