import { Result } from 'antd-mobile'
import React from 'react'

const Faild = () => {
    return (
        <div>
            <Result
                status='error'
                title='无法完成操作'
                description='请点击屏幕左上角关闭页面'
            />
        </div>
    )
}

export default Faild
