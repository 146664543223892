import { useState } from "react";
import { DatePicker, Dialog } from "antd-mobile";
import { Form, Input, Button } from "antd-mobile";
import dayjs from "dayjs";
import { SubVisForm } from "../api/v1";
import { useNavigate } from "react-router-dom";
import { ExclamationShieldOutline } from "antd-mobile-icons";

const VisView = () => {
  const nagative = useNavigate();

  const onFinish = async (values: any) => {
    values.openid = window.location.search;

    const { status } = await SubVisForm(values);
    if (status === 200) {
      nagative("/ok");
    } else {
      nagative("/bad");
    }
  };

  const [pickerStartVisible, setPickerStartVisible] = useState(false);
  const [pickerEndVisible, setPickerEndVisible] = useState(false);
  const [visible, setVisible] = useState(true);

  const [form] = Form.useForm();

  return (
    <div>
      <Dialog
        actions={[
          {
            key: "online",
            text: "`我已阅读并同意`",
            onClick: () => {
              setVisible(false);
            },
          },
        ]}
        visible={visible}
        content={
          <p>
            <h2>
              <b style={{ textAlign: "center" }}>
                <div>
                  <ExclamationShieldOutline />
                  入厂须知
                </div>
              </b>
            </h2>
            <h4>一、所有入厂人员必须遵守：</h4>
            1. 不得赤膊；
            <br /> 2. 不得穿拖鞋；
            <br /> 3. 不得穿短裤、背心；
            <br /> 4. 不得携带违禁物品进入公司；
            <br /> 5. 不得穿超短裙、吊带衣装；
            <br /> 6. 不得带小孩或动物进入公司；
            <br /> 7. 禁止边走边阅读及使用手机；
            <br /> 8. 严禁在公司内嬉戏打闹和奔跑；
            <br /> 9. 厂内严禁饮用酒精性饮料和服用非法药品；
            <br /> 10. 必须依规定办理携带进厂及携带出厂的物品；
            <br /> 11. 公司内除了在规定的吸烟点外，一律禁止吸烟；
            <br /> 12. 未经许可不得在厂区（或厂房内）照相或摄像；
            <br /> 13.
            只能在人行道或人行横道上行走，在交叉道口注意有无来往车辆；
            <br /> 14.
            进入生产作业区域，除上述着装要求外，还应束扎长发,且必须穿戴相应的劳保用品；
            <br />
            15. 访客进入厂区内必须有睿博光电工作人员陪同，不得任意在厂区内活动；
            <br />
            16.
            发生紧急事件（包括人员受伤或疾病、财产损失、工作场所环境损害、暴力威胁等）时，采取初步的紧急应变措施，并立即联络安全工程师。
            <h4>二、供应商除遵守上述规章外，还必须做到：</h4>
            1. 禁止关闭消防阀门。
            <br />
            2. 用电时，禁止使用金属梯子，以免触电。
            <br /> 3. 切实遵守能源控制及动力上锁要求。
            <br /> 4. 切实遵守高空作业要求，办理高空作业许可证。
            <br /> 5. 切实遵守动火作业要求，办理动火作业许可证。
            <br /> 6. 切实遵守密闭空间进入要求，办理密闭空间进入许可证。
            <br /> 7. 供应商的安全代表必须随时在作业监督现场，不得擅自离开。
            <br /> 8. 不论储存或使用中，气体钢瓶须保持直立，并加金属链子固定。
            <br /> 9.
            消防水只限于消防灭火用，不可用于其它用途（例如：清洗路面）。
            <br /> 10.
            非经睿博光电代表许可不能使用睿博光电的机械、设备（例如：叉车、登高车等）。
            <br />
            11.
            机械、设备（例如：叉车、移动式起重机）进入厂区须有检查合格证，操作人员必须持有资格证。
            <br />
            12.
            若使用睿博光电之灭火器，须于使用完后通知睿博光电安全代表。动火作业须将灭火器放置于作业区附近，不可使用现场灭火器，除非意外事件发生。
            <h4>三、处罚：</h4>
            凡违反上述规定的，公司有权对其进行批评教育，并根据其情节轻重和相关的规章制度，予以不同程度的处罚。
            <br />
            注：上述仅是睿博光电部分规章制度，所有入厂人员皆应遵守睿博光电之所有规章制度，
            <br />
            如有任何疑问，请联系流程管理部EHS工程师。
            <br />
            入厂人员承诺：我已清楚上述入厂须知要求，如有违反我将接受相应的处罚和责任！
            <br />
          </p>
        }
      />
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        footer={
          <Button block type="submit" color="primary">
            提交
          </Button>
        }
      >
        <Form.Item label="姓名">
          <Form.Item
            name="name"
            noStyle
            rules={[{ required: true, message: "请输入您的姓名" }]}
          >
            <Input minLength={2} maxLength={8} />
          </Form.Item>
        </Form.Item>

        <Form.Item label="手机号码">
          <Form.Item
            name="mobile"
            noStyle
            rules={[{ required: true, message: "请输入您的手机号码" }]}
          >
            <Input minLength={11} maxLength={12} />
          </Form.Item>
        </Form.Item>
        <Form.Item label="职务">
          <Form.Item
            name="job"
            noStyle
            rules={[{ required: true, message: "请输入您的职务" }]}
          >
            <Input minLength={2} maxLength={8} />
          </Form.Item>
        </Form.Item>
        <Form.Item label="来访单位">
          <Form.Item
            name="company"
            noStyle
            rules={[{ required: true, message: "请输入您的单位名称" }]}
          >
            <Input />
          </Form.Item>
        </Form.Item>

        <Form.Item label="最近行程">
          <Form.Item
            name="relateVis"
            noStyle
            rules={[{ required: true, message: "请输入您的14天内行程" }]}
          >
            <Input />
          </Form.Item>
        </Form.Item>

        <Form.Item label="被访人">
          <Form.Item name="visOpenid" noStyle>
            <Input minLength={2} maxLength={8} />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="startOn"
          label="开始时间"
          trigger="onConfirm"
          rules={[{ required: true, message: "请选择开始时间" }]}
          onClick={() => {
            setPickerStartVisible(true);
          }}
        >
          <DatePicker
            precision="minute"
            visible={pickerStartVisible}
            min={new Date()}
            onClose={() => {
              setPickerStartVisible(false);
            }}
          >
            {(value) =>
              value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "请选择开始时间"
            }
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="endOn"
          label="结束时间"
          rules={[{ required: true, message: "请选择结束时间" }]}
          trigger="onConfirm"
          onClick={() => {
            setPickerEndVisible(true);
          }}
        >
          <DatePicker
            precision="minute"
            visible={pickerEndVisible}
            // defaultValue={new Date()}
            min={new Date()}
            onClose={() => {
              setPickerEndVisible(false);
            }}
          >
            {(value) =>
              value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "请选择结束时间"
            }
          </DatePicker>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VisView;
