import { Button, Card } from 'antd-mobile'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DealOrder, FindOrder } from '../api/v1'

const ValView = () => {

    const nagative = useNavigate()

    const [state, setstate] = useState({
        "id":"",
        "openid": "",
        "visOpenid": "",
        "startOn": "2011-12-29 05:28:00",
        "endOn": "2011-12-29 05:28:00",
        "name": "测试",
        "mobile": "13011112222",
        "company": "未知公司",
        "job": "销售",
        "relateVis": "重庆",
        "validateState": false
    })

    const getInfo = async (param: string) => {
        const { data } = await FindOrder(param)
        return data
    }

    const SubOrder = async (statu: boolean) => {
        let data = state
        data.validateState = statu
        const { status } = await DealOrder(data)
        if (status === 200) {
            nagative("/ok")
        } else {
            nagative("/bad")
        }
    }


    useEffect(() => {
        getInfo(window.location.search).then(res => {
            const { result } = res
            console.log(res)
            setstate(result)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div>
            <Card title='姓名' >
                {state.name}
            </Card>
            <Card title='开始时间' >
                {state.startOn}
            </Card>
            <Card title='结束时间' >
                {state.endOn}
            </Card>
            <Card title='手机号码' >
                {state.mobile}
            </Card>
            <Card title='公司' >
                {state.company}
            </Card>
            <Card title='职务' >
                {state.job}
            </Card>
            <Card title='14天行程' >
                {state.relateVis}
            </Card>
            <Card title='审核' >
                <Button color='success'
                    onClick={() => {
                        SubOrder(true)
                    }}
                >
                    同意
                </Button>
                <Button color='danger'
                    onClick={() => {
                        SubOrder(false)
                    }}
                >
                    拒绝
                </Button>
            </Card>

        </div>
    )
}


export default ValView
