import { Result } from 'antd-mobile'
import React from 'react'

const QrCodeUnTime = () => {
    return (
        <div>
            <Result
                status='error'
                title='您的拜访时间还未开始'
                description='请在规定时间拜访'
            />
        </div>
    )
}

export default QrCodeUnTime
