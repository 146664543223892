import { Button, Form, Input } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import { userLogic } from '../api/v1'

const RigView = () => {
    const nagative = useNavigate()
    // const [orgs, setorgs] = useState([{
    //     "label": "",
    //     "value": ""
    // }])
    // const [value, setValue] = useState<string | null>("")
    const onFinish = async (values: any) => {
        // values.openid = window.location.search
        // // values.orgName = value
        // console.log(values)
        const { status } = await userLogic(values,window.location.search)
        if (status === 200) {
            nagative("/ok")
        } else {
            nagative("/bad")
        }
    }
    // const getOrgs = async () => {
    //     const { data } = await ListOrg()
    //     console.log(data)
    //     return data
    // }
    // useEffect(() => {
    //     getOrgs().then(res => {
    //         const { result } = res
    //         setorgs(result)
    //     })
    // }, [])
    return (
        <div>
            <Form
                onFinish={onFinish}
                footer={
                    <Button block type='submit' color='primary'>
                        提交
                    </Button>
                }
            >
                <Form.Item
                    name='username'
                    label='账户'
                    rules={[{ required: true, message: '账户不能为空' }]}
                >
                    <Input placeholder='请填写姓名' />
                </Form.Item>
                {/* <Form.Item label='部门'>
                    <Form.Item
                        noStyle
                    >
                        <CascadePickerView
                            options={orgs}
                            onChange={(val) => {
                                setValue(val[0])
                            }}
                            style={{ '--height': '320px' }} />
                    </Form.Item> */}
                {/* </Form.Item> */}
                <Form.Item
                    name='superPassword'
                    label='密钥'
                    rules={[{ required: true, message: '账户不能为空' }]}
                >
                    <Input placeholder='请填写密钥' />
                </Form.Item>
            </Form>
        </div >
    )
}

export default RigView
